// ============================================================================
// Calculator =================================================================
// ============================================================================

type KeyAccessor = {[index: string]: any} 

interface IScore {
  organicSqft: number,
  localFarming: number,
  climateChange: number,
  animalWelfare: number,
  soilHealth: number,
  waterReduction: number,
}

interface IState {
  [protiens: string]: KeyAccessor,
}

let state: IState | KeyAccessor = {
  proteins: {},
  rice: {},
  beans: {},
  toppings: {},
  tortilla: {},
  options: {} 
}

const limits: KeyAccessor = {
  proteins: 2,
  rice: 2,
  beans: 2,
  toppings: 100,
  tortilla: 1,
  options: 100
}

const defaultsForMenu: KeyAccessor = {
  'salad-bowl': {
    'climateChange': 0.15663
  }
}

const kidsLimits: KeyAccessor = {
  ...limits,
  proteins: 1
}

const ingredients: JQuery<HTMLElement> = $('.ingredient')
const options: JQuery<HTMLElement> = $('.option')
let veggieOption: string | undefined
let selectedMenu: string | undefined
let oneTaco: boolean = false

// Add unique identifiers and categories to each ingredient
ingredients.each(function(index: number) {
  const uid = btoa(this.className)
  const category = $(this).parents('.ingredientcatblock').data('category')

  $(this).data('uid', `${uid}-${index}`)
  $(this).data('category', category)
})

// Calculates the score of a data point for an ingredient
const score = (ingredient: JQuery<HTMLElement>, field: string) => {
  return Number(ingredient.data(field) || 0)
}

const handleNoneIngredient = (ingredient: JQuery<HTMLElement>, category: string): boolean => {
  if (ingredient.data('badge') !== 'none') return false

  state[category] = {}
  return true
}

const handleRemoveNoneIngredient = (category: string) => {
  const includesNoneIngredient = 
    Object.keys(state[category])
      .map(key => state[category][key]['badge'])
      .includes('none')

  if (includesNoneIngredient) state[category] = {}
}

const updateBadgeForHalfOrders = (category: string): void => {
  const applicableCategories = ['proteins', 'beans', 'rice']
  const selectedOptions = Object.keys(state[category])
  const optionMultipliers = selectedOptions.map(option => state[category][option]['multiplier'])
  let updatedOptions = state[category]

  // If the category is not applicable or there are less than two options selected correct the badges
  if (!applicableCategories.includes(category) || selectedOptions.length < 2) {
    const badgeUpgrades: KeyAccessor = {
      half: {
        badge: 'normal',
        multiplier: 1
      },
      '1x': {
        badge: '2x',
        multiplier: 2,
      }
    }

    updatedOptions = selectedOptions.reduce((acc: object, option: string) => {
      const currentOption = state[category][option]
      const {badge, multiplier} =  badgeUpgrades[currentOption.badge] || { ...currentOption }

      return {...acc, [option]: {...currentOption, badge, multiplier }}
    }, {})
  }

  // If there are two options selected and both have a multiplier of 1 set badges to 1/2
  else if (optionMultipliers.every(multiplier => multiplier === 1)) {
    updatedOptions = selectedOptions.reduce((acc: object, option: string) => {
      return {...acc, [option]: {
        ...state[category][option],
        badge: 'half',
        multiplier: 0.5,
      }}
    }, {})
  }

  else if (optionMultipliers.includes(2)) {
    updatedOptions = selectedOptions.reduce((acc: object, option: string) => {
      return {...acc, [option]: {...state[category][option], badge: '1x', multiplier: 1}}
    }, {})
  }

  state = {...state, [category]: updatedOptions}
}

const handleVeggieSelectionToggle = (ingredient: JQuery<HTMLElement>, category: string) => {
  if (category !== 'proteins') return  

  const veggieSelected: boolean = !!$(ingredient).find('h3').text().match(/veggie/i)
  let guacElem = $(ingredient).parents('.builder').find('.guacamole')

  // There are 2 guacs in tacos. For Three tacos the correct one is selected by default
  // If only one taco then we need to select the second guac.
  if (selectedMenu === 'tacos') {
    const option = oneTaco ? 1 : 0
    guacElem = $(guacElem[option])
  }

  if (veggieSelected) {
    veggieOption = $(ingredient).data('uid')
    state[category] = {}
    updateState(guacElem, 'toppings')
  } else {
    veggieOption && delete state[category][veggieOption]
    veggieOption && delete state['toppings'][guacElem.data('uid')]
    veggieOption = undefined
  }
}

const handlePreSelection = () => {
  if (selectedMenu === 'salad-bowl') {
    const elem = $('#builder-salad > div.ingredientcatblock.toppings > div.ingredientcat_ingredients > div:nth-child(10)')
    updateState(elem, 'toppings')
    updateScores()
    updateBadges()
  }
}

const updateState = (ingredient: JQuery<HTMLElement>, category: string, multiplier: number = 1, badge: string = 'normal') => {
  handleNoneIngredient(ingredient, category)
  handleRemoveNoneIngredient(category)
  handleVeggieSelectionToggle(ingredient, category)
  const isKidsMenu = selectedMenu === 'kids-meal' || selectedMenu === 'kids-meal-quesadilla'
  const isTacosMenu = selectedMenu === 'tacos'
  const limit = isKidsMenu ? kidsLimits[category] : limits[category]

  // Since kid meals can only have one ingredient in 
  if (isKidsMenu && ['proteins', 'rice', 'beans'].includes(category)) {
    state[category] = {}
  }

  if (isTacosMenu && category ==='proteins') {
    state[category] = {}
  }

  if (limit > Object.keys(state[category]).length) {
    state[category]  = {...state[category], [ingredient.data('uid')]: {
      organicSqft:    score(ingredient, 'organicsqft'),
      localFarming:   score(ingredient, 'localfarming'),
      climateChange:  score(ingredient, 'climatechange'),
      animalWelfare:  score(ingredient, 'animalwelfare'),
      soilHealth:     score(ingredient, 'soilhealth'),
      waterReduction: score(ingredient, 'waterreduction'),
      multiplier,
      badge
    }}
  } else {
    alert(`Sorry, you can only select ${limit} ${category}.`)
  }
}

const updateScores = () => {
  const getScoreElement = (name: string) => $('div').find(`[data-score="${name}"]`)

  // Flatten all scores so that it's a bit easier to fetch their values
  const allScores = Object.keys(state).reduce((acc: Array<IScore>, category: string): Array<IScore> => {
    const choices: KeyAccessor = state[category]
    const flat = Object.keys(choices).map(key => choices[key])

    return [...acc, ...flat]
  }, [])

  const setValue = (element: JQuery<HTMLElement>, targetValue: string): void => {
    let score = allScores.reduce((acc: number, score: KeyAccessor) => {
      return acc + (score[targetValue] * score.multiplier)
    }, 0)

    if (selectedMenu === 'tacos' && oneTaco) {
      score = score / 3
    }

    // Add the defaults 
    score = score + (defaultsForMenu[selectedMenu || '']?.[targetValue] || 0)

    element.text(((Math.round(score * 10) / 10)).toFixed(1))
  }

  setValue(getScoreElement('organic-sq-ft'), 'organicSqft')
  setValue(getScoreElement('local-farming'), 'localFarming')
  setValue(getScoreElement('climate-change'), 'climateChange')
  setValue(getScoreElement('animal-welfare'), 'animalWelfare')
  setValue(getScoreElement('soil-health'), 'soilHealth')
  setValue(getScoreElement('water-reduction'), 'waterReduction')
}

const updateBadges = () => {
  ingredients.each(function() {
    const ingredient = $(this)
    const uid = $(this).data('uid')
    const category = $(this).data('category')

    try {
      const option = state[category][uid]
      const badges = ingredient
        .children('.ingredient__nameimage')

      // Hide all of the badges
      badges.children('.badge').css('display', 'none')
      // Show the badge based on the selected item
      if (option) {
        const badge = option.badge === 'none' ? 'normal' : option.badge // none should be the normal badge
        badges.find(`.badge__${badge}`).css('display', 'block')
      }
    } catch (error) {
      // console.log(uid, category, ingredient)
    }
  })
}

const resetContent = (resetMenu: boolean = false) => {
  state = {
    proteins: {},
    rice: {},
    beans: {},
    toppings: {},
    tortilla: {},
    options: {} 
  }

  if (resetMenu) selectedMenu = undefined
  oneTaco = false

  updateScores()
  updateBadges()
}

const doTheThings = (ingredient: JQuery<HTMLElement>, category: string, multiplier: number = 1, badge: string = 'normal', type: string =  'ingredient') => {
    const uid: string = ingredient.data('uid')
    const currentlySelected: KeyAccessor = state[category][uid]
    const sameSelection: boolean = currentlySelected && currentlySelected['multiplier'] === multiplier

    delete state[category][uid]

    if (type === 'ingredient' && !currentlySelected) {
      updateState(ingredient, category, multiplier, badge)
    }

    else if (type === 'option' && !sameSelection) {
      updateState(ingredient, category, multiplier, badge)
    }

    updateBadgeForHalfOrders(category)
    updateBadges()
    updateScores()
}

//==============================================
// Set click event handlers
//==============================================

ingredients.each((_: number, elem: HTMLElement) => {
  const ingredient = $(elem)

  ingredient.children('.ingredient__nameimage').on('click', function () {
    const category = ingredient.data('category')
    const multiplier = 1
    const badge = ingredient.data('badge') || 'normal'

    doTheThings(ingredient, category, multiplier, badge, 'ingredient')
  })
})

// When the user clicks an option add it to state and update the scores
options.each((_: number, opt: HTMLElement) => {
  const option = $(opt)

  option.on('click', () => {
    const ingredient: JQuery<HTMLElement> = option.parents('.ingredient')
    const category: string = ingredient.data('category')
    const multiplier: number = Number(option.data('multiplier') || 1)
    const badge: string = option.data('badge')

    doTheThings(ingredient, category, multiplier, badge, 'option')
  })
})


$('.menulink').on('click', () => resetContent(true))
$('.tryagain').on('click', () => resetContent(true))
$('.tacotoggle .toggle').on('click', () => resetContent(false))

$('.menuitem__title.main').on('click', function () {
  selectedMenu = $(this).data('menu')
  handlePreSelection()
})

$('.tacotoggle > .toggle').on('click', function () {
  oneTaco = $(this).hasClass('one-taco')
})

$('.menuitem__title.kids').on('click', function() {
  if ($(this).text().match(/ques/ig)) {
    selectedMenu = 'kids-meal-quesadilla'
  }
})

// ============================================================================
// Other bits =================================================================
// ============================================================================

// Watch modal close and reload reload iframes

$('#close-modal').on('click', () => {
  const frames = document.getElementsByTagName('iframe')
  Array.from(frames).forEach(elem => elem.src = elem.src)
})

// Transitions

$('.tile-button').on('click', function (e) {
  if (navigator.appVersion.indexOf("Chrome/") != -1) {
    e.preventDefault()
    const target = $(this).data('target')
    const href = $(this).attr('href') || ''
    const elem = $('.transition-tile[data-target="' + target + '"]')
    const transitionSpeed = 550

    elem.show()
    elem.css('height', '90vh');
    elem.css('opacity', 1);

    setTimeout(function () {
      window.location.hash = ''
      window.location.pathname = href
    }, transitionSpeed) //should be equal to the value set in CSS + about 100ms to let the animation finish
  }
})

const setTransitionOutTiles = () => {
  if (navigator.appVersion.indexOf("Chrome/") != -1) {
    $('.transition-out-tile').css('opacity', '0')

    setTimeout(function () {
      $('.transition-out-tile').remove()
    }, 600)
  } else {
    $('.transition-out-tile').remove()
  }
}

setTransitionOutTiles()
